import React, { useRef, useState, useEffect } from 'react';
import styled from 'styled-components';
import { useAuth } from '../context/AuthContext';
import { useTransition, animated, config } from 'react-spring';
import { withRouter } from 'next/router';
import { signin } from '../redux/modules/auth/actions';
import {
	setBgColor,
	setVisibleNav,
} from '../redux/modules/superStyling/actions';
import { useDispatch, useSelector } from 'react-redux';
const Signin = ({ router }) => {
	const emailRef = useRef();
	const passwordRef = useRef();

	const [loading, setLoading] = useState(false);
	const { user } = useSelector((state) => state.auth);
	// const { error } = useSelector((state) => state.auth);
	const dispatch = useDispatch();
	const handleSubmit = (e) => {
		e.preventDefault();
		dispatch(signin(emailRef.current.value, passwordRef.current.value));
	};
	useEffect(() => {
		dispatch(setVisibleNav(false));
	}, []);

	useEffect(() => {
		if (Object.keys(user).length > 0) {
			router.push(`/user`, '/');
		}
	}, [user]);

	return (
		<Container>
			{/* <div className="login-routes">
				<a>Login for students</a>
				<a>Login for teachers</a>
			</div> */}

			<div className="signin-card">
				<h2 className="title">Identifiez-vous</h2>
				<div className="images"></div>
				{/* {error && (
					<div className="error-display">
						<span>{error}</span>
					</div>
				)} */}
				<form onSubmit={handleSubmit}>
					<div className="form-group">
						<input type="email" ref={emailRef} required placeholder="E-mail" />
					</div>
					<div className="form-group">
						<input
							type="password"
							ref={passwordRef}
							required
							placeholder="Mot de passe"
						/>
					</div>

					<button disabled={loading} type="submit">
						Se connecter
					</button>
				</form>
			</div>
		</Container>
	);
};

const Container = styled.div`
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	height: 100vh;
	z-index: 1;
	background-image: url(/static/images/login-background.jpg);
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	.login-routes {
		display: flex;
		justify-content: space-between;
		background: rgba(255, 255, 255, 0.1);
		backdrop-filter: blur(4px);
		padding: 1em;
		margin: 1em;
		border-radius: 12px;
		a {
			padding: 1em;
			color: ${(props) => props.theme.colors.text};
			cursor: pointer;
			font-size: 0.9em;
		}
	}
	.signin-card {
		width: 400px;

		border: 1px solid ${(props) => props.theme.colors.primary};
		border-radius: 12px;
		padding: 1em;
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;
		background: rgba(255, 255, 255, 0.1);
		backdrop-filter: blur(4px);

		.title {
			color: ${(props) => props.theme.colors.text};
			font-size: 1.3em;
		}
		.error-display {
			width: 100%;
			margin: 0.4em;
			padding: 1em;
			text-align: center;
			background: ${(props) => props.theme.colors.alert};
			span {
				padding: 0.4em;

				color: ${(props) => props.theme.colors.white};
			}
		}
		form {
			width: 100%;
			display: flex;
			justify-content: center;
			align-items: center;
			flex-direction: column;
			.form-group {
				width: 100%;
				padding: 1em;
				input {
					width: 100%;
					font-size: 1em;
					line-height: 2em;
					border: 1px solid ${(props) => props.theme.colors.text};
					border-radius: 12px;
					background: transparent;
					color: ${(props) => props.theme.colors.text};
					padding-left: 6px;
					&::placeholder {
						color: ${(props) => props.theme.colors.text};
						opacity: 0.5;
					}
					:focus {
						color: ${(props) => props.theme.colors.text};
					}
				}
			}
			button {
				display: inline-block;
				font-weight: 400;
				text-align: center;
				white-space: nowrap;
				vertical-align: middle;
				-webkit-user-select: none;
				-moz-user-select: none;
				-ms-user-select: none;
				user-select: none;
				border: 1px solid ${(props) => props.theme.colors.text};
				padding: 0.4em 0.8em;
				font-size: 0.8em;
				line-height: 1.5;
				border-radius: 12px;
				color: ${(props) => props.theme.colors.text};
				background-color: transparent;
				cursor: pointer;
			}
		}
	}
`;

export default withRouter(Signin);
